import { isNud } from '@/utilities/typing'
import Car from '@/models/entities/car';
import Schedule from '@/models/entities/schedule';

/**
 * スケジュール詳細モーダル社用車状況表示のためのエンティティ
 */
class ScheduleCarType {
    car;
    schedules;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.car = new Car(properties.car);
        this.schedules = [];
        if ('schedules' in properties) {
            for (let schedule of properties.schedules) {
                this.schedules.push(new Schedule(schedule));
            }
        }
    }
}

export default ScheduleCarType;
