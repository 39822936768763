import Enum from '../enum'

/**
 * 応援カメラマン依頼ステータス
 */
class InvitationStatus extends Enum {
    static DONE = 1; // 依頼済
    static FREE = 2; // 依頼まだ（空き）
    static CONFIRM = 3; // 確認中
    static CANCEL = 8; // キャンセル
    static DISABLE = 9; // 不可

    static values() {
        return {
            [this.DONE]: '依頼済',
            [this.FREE]: '依頼まだ(空き)',
            [this.CONFIRM]: '確認中',
            [this.CANCEL]: 'キャンセル',
            [this.DISABLE]: '不可',
        }
    }
}

export default InvitationStatus;