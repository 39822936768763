import { isNud } from '@/utilities/typing';
import { dateYmd, dateYmdForInputDate } from '@/utilities/date-format';

/**
 * 社用車マスタ エンティティ
 */
class Car {
    car_id;
    car_name;
    shortened_name;
    age_limit;
    car_number;
    expired_date;
    priority;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.car_id = properties.car_id;
        this.car_name = properties.car_name;
        this.shortened_name = properties.shortened_name;
        this.age_limit = properties.age_limit;
        this.car_number = properties.car_number;
        this.expired_date = properties.expired_date;
        this.priority = properties.priority;
    }

    get expired_date_display() {
        return dateYmd(this.expired_date);
    }

    get expired_date_for_input() {
        return dateYmdForInputDate(this.expired_date);
    }

    set expired_date_for_input(value) {
        return this.expired_date = value;
    }
}

export default Car;
