import Enum from '../enum'

/**
 * スケジュール 時間未定の種類
 *
 * [1:AM 2:PM 3:放課後 4:不明]
 */
class UnfixedType extends Enum {
    static AM = 1; // AM
    static PM = 2; // PM
    static AFTER_SCHOOL = 3; // 放課後
    static UNCERTAIN = 4; // 不明

    static values() {
        return {
            [this.AM]: 'AM',
            [this.PM]: 'PM',
            [this.AFTER_SCHOOL]: '放課後',
            [this.UNCERTAIN]: '不明',
        }
    }
}

export default UnfixedType;