import Enum from '../enum'

/**
 * スケジュール 雨天時対応 複数日の場合のみ使用
 *
 * [1:雨天決行 3:雨天中止]
 */
class RainTypeExceptPostpone extends Enum {
    static CARRY_OUT = 1; // 雨天決行
    static CANCEL = 3; // 雨天中止

    static values() {
        return {
            [this.CARRY_OUT]: '雨天決行',
            [this.CANCEL]: '雨天中止',
        }
    }
}

export default RainTypeExceptPostpone;