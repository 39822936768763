<template>
    <div class="form-control bg-light min-h-38" :class="optional_class">{{ text }}</div>
</template>

<script>
export default {
    name: 'ReadOnly',
    props: {
        text: {
            type: [String, Number]
        },
        optional_class: {
            type: String,
            default: ''
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.min-h-38 {
    /* textが空白の場合高さ合わせる */
    min-height: 38px;
}
</style>
